$menu-footer: (
	link-padding: 0px 0,
	link-modifiers: (
		normal: (
			color: global-settings(colors,grey),
		),
		hover: (
			color: global-settings(colors, primary),
		),
	),
	size:(
		mobile:(
			font-size: 14px,
			line-height:28px,
			font-weight: 600,
			text-align: left,
		),
		tablet:(
			font-size: 18px,
			line-height:28px,
			
			text-align: left,
		)
	)
);

.menu-footer {
	$this: &;
	@extend %list-reset;
	li:first-child{
		#{$this}__link{
			padding-top: 0px;
		}
	}
	li:last-child{
		#{$this}__link{
			padding-bottom: 0px;
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		transition: 0.3s color ease-in-out;
		padding: map-get-strict($menu-footer, link-padding);
		@include link($menu-footer, link-modifiers);
		@include responsive($menu-footer,size);
		&:hover{
			#{$this}__link-text{
				transform: translateX(.3125rem);
			}
		}
	}

	&__link-text{
		display:inline-block;
		transform: translateX(0);
		transition: transform .3s cubic-bezier(.165,.84,.44,1);
    }
}
