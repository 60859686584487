.ant-layout {
    &.layout {
        min-height: 100vh;
    }

    &.ant-layout-has-sider-override {
        &.ant-layout-has-sider{
            flex-direction: column;
            @include media(tablet up) {
                flex-direction: row;
            }

            .ant-layout {
                width: 100%;
            }   
        }        
    }
    
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #434343;
    }
}
