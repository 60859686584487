.footer-links{
    &__heading{
        margin-bottom: 10px;
        text-align: left;
        text-transform: uppercase;
        @include media(tablet up){
            text-align: left;
            margin-bottom: 15px;
        }
    }
}