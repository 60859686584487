.reservationsIntro {
    @extend %site-container;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 350px;
    }

    @include media(desktop up) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: $pad * 5;
    }
}