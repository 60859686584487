$drawer: (
  width: 250px,
  shadow: rgba(global-settings(colors, white), 0.8),
  colors: (
    background: global-settings(colors,black),
    linkColor: global-settings(colors,grey)
  ),
  padding: global-settings(gutters, default) 0,
  z-index: 99,
);

.drawer {
  $menu: &;
  position: fixed;
  top: 100px;
  height: 100%;
  z-index: map-get-strict($drawer, z-index);
  margin: auto;
  padding: map-get-strict($drawer, padding);
  display: block;
  background: map-get-deep($drawer, colors, background);
  width: map-get-strict($drawer, width);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  // z-index: -1;
  overflow-x: hidden;
	box-shadow: 0 0 3px map-get-strict($drawer, shadow);

  @include media(desktop up) {
    display: none;
  }

  &__position--left {
		left: 0;
		transform: translate3d(-100%, 0, 0);
	}

	&__position--right {
		right: 0;
		transform: translate3d(100%, 0, 0);
	}

	&__position--top {
		width: 100%;
		transform: translate3d(0, -100%, 0);
	}

	&__position--behind {
		width: 100%;
		transform: translate3d(0, 0, 0);
		opacity: 0;
    z-index: -1;
		top: 0;
	}

  .menu-vertical{
    font-weight: bold;
  }
  .menu-vertical__link{
    color: map-get-deep($drawer, colors, linkColor);
  }

  &__language-switcher{
		margin-top: 30px;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
		padding: 25px;
	}
}

body.menu-is-open {
	overflow: hidden;
	.header{
		background: map-get-deep($drawer, colors, background);
	}
    
	.drawer {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		z-index: 99;
		padding-top: 80px;
	}
}

