$footer-contact:(
    link-colors: (
        normal: global-settings(colors,grey),
        hover: global-settings(colors,primary)
    ),
    link-sizes: (
        mobile: (
            font-size: 14px,
            line-height: 20px,
        ),
        tablet: (
            font-size: 18px,
            line-height: 28px,
        ),
    ),

);

.footer-contact {
    display: flex;
    flex-direction: column;

    &__logo{
        margin-bottom: 30px;
        .logo{
            width: auto;
        }
        @include media(tablet up){ 
            margin-bottom: 30px;
        }
    }

    &__heading{
        margin-bottom: 10px;
        @extend %heading-reset;
        text-align: left;
        text-transform: uppercase;
        @include media(tablet up){
            text-align: left;
            margin-bottom: 15px;
        }
    }

    &__address{
        display: flex;
        font-style: normal;
        flex-direction: column;
        margin: 0;
    }

    &__item{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        color: map-get-deep($footer-contact , link-colors, normal );
        @include media(tablet up){
            text-align: left;
            align-self: flex-start;
            justify-content: flex-start
        }
        &:last-child(){
            margin-bottom: 0px;
        }
    }
    &__item-text{
        display: flex;
    }
    &__icon{
        color: map-get-deep($footer-contact , link-colors, normal );
        fill:  map-get-deep($footer-contact , link-colors, normal );
        margin-right: 15px;
    }
    &__link{
        color: map-get-deep($footer-contact , link-colors, normal );
        text-decoration: none;
        @include responsive($footer-contact,link-sizes);
        transform: translateX(0);
        transition: transform .3s cubic-bezier(.165,.84,.44,1);
        &:hover{
            color: map-get-deep($footer-contact , link-colors, hover );
			transform: translateX(.3125rem);
        }
    }

    &__info-wrap{
        display: flex;
        flex-direction: column;
        @include media(tablet up){
            margin-left: 40px;
        }
        .language-switcher{
            margin-top: 30px;
        }
    }
}