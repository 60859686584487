$footer:(
    height: auto,
    site-padding: global-settings(gutters, default),
    site-padding-big: global-settings(gutters, big),
    site-container: global-settings(containers, default),
    border-color: global-settings(colors, dark),
    colors: (
      background: global-settings(colors, black),
      link-normal: global-settings(colors,grey),
      link-hover: global-settings(colors,primary),
      divider: rgba(255, 255, 255, 0.1),
      rgbalightgrey: rgba(#fff,.4),
    ),
);

.footer{
    $this: &;
    padding: 0px map-get-strict($footer, site-padding);
    top: 0;
    background: map-get-deep($footer, colors, background);
    height: map-get-strict($footer, height);
    width: 100%;
    position: relative;
    
    &__wrapper {
        max-width: map-get-strict($footer, site-container);
        margin: 0 auto;
        width: 100%;
        // height: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        @include media(desktop up) {
          padding-top: 60px;
          padding-bottom: 60px;
          grid-template-columns: 2fr 1fr 1fr min-content;
          grid-template-rows: auto;
          display: grid;
          grid-template-rows: auto;
          gap: 0px 0px;
        }
      }

      &__logo{
        .logo__img{
          width: 48px;
        }
      }

      &__footer-contact{
        padding-bottom: 40px;
        margin-bottom: 40px;
        flex: 0 0 100%;
        flex-direction: column;
        border-bottom: 1px solid map-get-deep($footer, colors, divider);;
        @include media(tablet up){
            flex: 0 0 50%;
            flex-direction: row;
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
      }

      &__footer-links{
        margin-bottom: 40px;
        flex: 0 0 50%;
        @include media(tablet up){
            flex: 0 0 20%;
            flex-direction: row;
            padding-top: 0;
            margin-bottom: 0;
            border-top: none;
        }
      }

      &__footer-social-icons{
        border-top: 1px solid map-get-deep($footer, colors, divider);
        padding-top: 40px;
        margin-bottom: 0px;
        flex: 0 0 100%;
        justify-content: space-evenly;
        @include media(tablet up){
            flex: 0 0 10%;
            flex-direction: row;
            justify-content:center;
            padding-top: 0;
            margin-bottom: 0;
            border-top: none;
        }
      }

      &__copyright{
        font-size: 13px;
        line-height: 20px;
        color: map-get-deep($footer, colors, rgbalightgrey);
        border-top: 1px solid map-get-deep($footer, colors, divider);
        max-width: map-get-strict($footer, site-container);
        margin: 0 auto;
        width: 100%;
        // height: 100%;
        padding: map-get-strict($footer, site-padding) 0px;
        text-align: left;
        @include media(tablet up){
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          padding: map-get-strict($footer, site-padding) 0;
        }
        a{
          text-decoration: none;
          color: global-settings(colors,grey);
          @include underline;
        }
      }
}
    