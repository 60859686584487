@import 'media-blender';

.reservationsPage {

    .games-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin: 25px 0;

        @include media(desktop up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .game {
        .time-title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }

        .summary {
            .item {
                display: grid;
                grid-template-columns: 1fr 1fr 2fr;

                .date {
                    font-weight: 700;
                }

                .time {
                    font-weight: 700;
                }

                .tank {
                    font-weight: 700;
                }
            }
        }

    }

    .tanks-grid {
        display: grid;
        grid-gap: 50px 0;
        margin-bottom: 75px;

        @include media(tablet up) {
            grid-gap: 0 50px;
            grid-template-columns: 1fr 1fr;
        }        
    }

    .tanks {
        display: grid;
        grid-template-rows: repeat(6, 1fr);
        row-gap: 25px;

        .tank-disabled {
            color: #6A6868;
            border-color: #6A6868;
        }

        .item {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;
            row-gap: 5px;
            column-gap: 25px;
            align-items: center;

            &.disabled {
                .number {
                    color: #6A6868;
                }
                .name {
                    color: #6A6868;
                }
                .icon {
                    color: #6A6868;
                }
                .action {
                    color: #6A6868;
                }
            }

            .number {
                font-size: 24px;
                font-weight: 700;
            }

            .name {
                font-weight: 700;
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: auto;

        @include media(desktop up) {
            margin: 0;
        }
    }

    .finish-button {
        width: 100%;

        @include media(tablet up) {
            width: 350px;
        }
    }

    .reservationErrorModal {
        &__buttons {
            display: flex;
            flex-direction: column;
            @include media(desktop up) {
                flex-direction: row;
            }

            .left-button {
                margin: 0 0 32px 0;
                @include media(desktop up) {
                    margin: 0 32px 0 0;
                }
            }
        }
    }
}
