$content-sidebar: (
  site-padding: global-settings(gutters, default),
  site-padding-big: global-settings(gutters, big),
  site-container: global-settings(containers, default),
  colors: (
    background: global-settings(colors, black),
  ),
);

.content-sidebar {
  $this: &;
  padding: 0px map-get-strict($content-sidebar, site-padding);
  top: 0;
  background: map-get-deep($content-sidebar, colors, background);
  width: 100%;
  position: relative;

  &__wrapper {
    max-width: map-get-strict($content-sidebar, site-container);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-top: map-get-strict($content-sidebar, site-padding);
    padding-bottom: map-get-strict($content-sidebar, site-padding);
    @include media(desktop up) {
      padding-top: map-get-strict($content-sidebar, site-padding);
      padding-bottom: map-get-strict($content-sidebar, site-padding);
      grid-template-columns: 1fr 4fr;
      grid-template-rows: auto;
      display: grid;
      grid-template-rows: auto;
      gap: 0px 50px;
    }
  }

  &__content {
    @include media(desktop up) {
    }
  }

  .logout {
    margin: 30px 0 50px 0;
    @include media(desktop up) {
      margin: 50px 0 10px 0;
    }    
  }
}
