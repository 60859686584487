.ant-picker {
    &.ant-picker-largest {
        @include text-size(huge);
        font-weight: 700;
        color: $color-text-gray;
        text-align: center;

        .ant-picker-input {
            input {
                @include text-size(huge);
                font-weight: 700;
                text-align: center;
            }
        }
    }
}