$header-menu: (
	link-padding: (
        mobile: (
            padding: 0 15px,
        ),
        tablet: (
            padding: 0 25px,
        ),
        large:(
            padding: 0 30px,
        )
    ),
	link-modifiers: (
		normal: (
			color: global-settings(colors, black),
		),
		hover: (
			color: global-settings(colors, primary),
		)
	),
);

.header-menu {
    $this: &;
    height: 100%;
    width: 100%;
    &__items{
        @extend %list-reset;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        #{$this}__item:first-child() #{$this}__link{
            padding-left: 0;
        }
        #{$this}__item:last-child() #{$this}__link{
            padding-right: 0;
        }
    }

    &__item{
      height: 100%;
    }

	&__link {
        position: relative;
		text-decoration: none;
		height: 100%;
		display: flex;
        font-size: 18px;
		line-height: 28px;
        svg{
			position: relative;
            fill: $color-text-gray;
		}
        &:hover{
			svg{
				fill: $color-primary;
			}
		}
		transition: 0.3s color ease-in-out;
        flex: 1 0 auto;
		@include link($header-menu, link-modifiers);
	}
    &__link-text, svg{
        font-weight: 700;
        font-size: 18px;
        display: block;
        line-height: 28px;
        color: $color-text-gray;
        position: relative;
        text-transform: uppercase;
        
        @include underline();
        transition: all 0.3s ease-in 0s;
        &::before{
            content: "";
            position: absolute;
            top: 2px;
            left:-8px;
            width: 5px;
            height: 5px;
            background-color: transparent;
            display: none;
            transition: background-color .3s ease,opacity .3s ease;
            border-radius: $border-radius;
        }

        &:hover{
            color: $color-primary;
            transform: translateY(-5px);
            &::before{
            background-color: $color-primary;
            }
        }
    }
    &__item--current_page_item{
        color: $color-primary;
        #{$this}__link-text{
            font-weight: 700;
            color: $color-primary;
            transform: translateY(0px);
            @include underline-text();
            transition: all 0.3s ease-in 0s;
            background-size: 100% 100%;
            //Experiment
            &:hover{
                color: $color-primary;
                transform: translateY(-1px);
            }

            &::before{
                background-color: $color-primary;
            // display: block;
            }
        }
    }
}
