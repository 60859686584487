.footer-social-icons{
    &__heading{
        margin-bottom: 10px;
        text-align: left;
        text-transform: uppercase;
        @include media(tablet up){
            text-align: left;
            margin-bottom: 15px;
        }
    }
    &__paragraph{
        margin-bottom: 15px;
        text-align: center;
        @include media(tablet up){
            text-align: left;
        }
    }

    &__social-links{
        text-align: center;
        align-self:center;

        flex-direction: row;
        justify-content: space-evenly;
        @include media(tablet up){
            text-align: left;
            margin-top: 4px;
            flex-direction: column;
            align-self: flex-end;
            align-items: flex-end;
            justify-content: flex-end;
        }
        margin-bottom: 15px;
    
    }

    &__link{

    }
}