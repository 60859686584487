@import "../../../assets/scss/tv.scss";

.field-preparation-page {
	width: 100%;
	height: 100%;
	padding: 64px;

	.banner {
		display: flex;
		justify-content: space-between;
		align-content: center;

		&__left {
			color: $color-fg-secondary;
			.divider-short {
				width: 164px;
				height: 4px;
				background-color: $color-fg-primary;
			}
			.title {
				margin-top: 8px;
				font-size: 60px;
			}
		}

		&__right {
			background-color: $color-bg-quaternary;
			color: $color-fg-quaternary;
			padding: 24px;
			min-width: 600px;
			.left {
				text-align: left;
				font-size: 36px;
			}
			.right {
				text-align: right;
				font-size: 80px;
				margin-left: 100px;
			}
		}
	}

	.spliter {
		width: 100%;
		height: calc(100% - 130px);

		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		margin-top: 16px;
	}

	.statistic-box {
		width: 100%;
		padding: 32px 0;
		background-color: $color-bg-secondary;

		min-height: 525px;

		&--left {
			margin-right: 16px;
		}
		&--right {
			margin-left: 16px;
		}

		&__table {
			width: calc(100% - 32px);
		}
	}
}
