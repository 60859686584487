$social-links: (
	icon-hover-color: global-settings(colors, primary),
	icon-normal-color: global-settings(colors, grey),
);

.social-links {
	@extend %list-reset;
	$this: &;
	display: flex;
	align-items: center;
	overflow: hidden;
	margin: -10px;
	flex-wrap: wrap;

	&__link {
		padding: 10px;
		display: flex;
		color: map-get-strict($social-links,icon-normal-color);
		transition: all 300ms ease 0s;
		&:hover{
			color: map-get-strict($social-links,icon-hover-color);
			transform: translateX(2px);
		}
	}

	&__link-text{
		margin-left:25px;
	}

	svg {
		display: block;
	}
}
