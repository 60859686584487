.language-switcher{
    display: flex;
    flex-direction: row;
    &__icon{
        line-height: 0.6;
        margin-right: 10px;
    }
    &__link{
        text-transform: uppercase;
    }

    &__sub-menu__link{
        text-transform: uppercase;
    }
}