.gameCard {
    border: 2px solid $border-color;
    display: grid;
    grid-template-rows: auto auto 45px auto;
    grid-template-areas: "icon" "time" "myTanks" "occupiedTanks";
    align-items: center;
    justify-items: center;
    padding: $pad * 2;
    cursor: pointer;
    row-gap: 15px;

    .time {
        grid-area: "time";
        @include text-size(huge);
        font-weight: 700;
        line-height: 1;
    }

    .myTanks {
        grid-area: "myTanks";
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
            color: $color-tertiary;
            font-size: 12px;
        }
    
        .status {
            img {
                height: 10px;
            }
        }
    }

    .occupiedTanks {
        grid-area: "occupiedTanks";
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
            color: $color-tertiary;
            font-size: 12px;
        }
    
        .status {
            img {
                height: 16px;
            }
        }
    }

    &.selected {
        border: 2px solid $color-primary;
    }
    
    &.occupied {
        border: 2px solid $color-primary;
        background-color: $color-primary;
        .time {
            color: $color-black;
        }
        .myTanks {
            .label,
            .status {
                color: $color-black;
            }
        }
        .occupiedTanks {
            .label,
            .status {
                color: $color-black;
            }
        }
    }

    &.finished {
        border: 2px solid $color-disabled;
        color: $color-disabled;
    }

    &.inactive {
        border: 2px solid red;
        color: $color-disabled;
    }
}