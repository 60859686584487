@import 'media-blender';

.gamesPage {
    padding: 0 20px;
    width: 100%;
    .games-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin: 25px 0;

        @include media(desktop up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .picker {
        display: flex;
        align-items: center;
        width: fit-content;
    }
}