.ant-descriptions {
    &.ant-descriptions-bordered {
        .ant-descriptions-view {
            border: 1px solid $color-tertiary;
        }

        .ant-descriptions-row {
            border-bottom: 1px solid $color-tertiary;
        }

        .ant-descriptions-item-label {
            background-color: $secondary-color;
        }

        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
            padding: 16px 24px;
            border-right: 1px solid $color-tertiary;
        }
    }
}
