/// Mixin that will output modifier classes for specific selector from map key.
///
/// @param {string} $map - Map to search for.
/// @param {string} $selector - HTML valid selector.
/// @param {string} $wrapper - Wrap output item with additional markup
///
/// Map:
/// $test: (
///   test: (
///     black: $base-black-color,
///     white: $base-white-color,
///     primary: $base-primary-color,
///   )
/// );
///
/// @example
/// .test {
///   @include modifiers-deep($test, test, color);
/// }
///
/// @output
/// ```scss
/// .test--black {
///   color: #000000;
/// }
/// .test--white {
///   color: #FFFFFF;
/// }
/// .test--primary {
///   color: #D8262C;
/// }
/// ```

@mixin modifiers-deep($map, $key, $selector, $wrapper: false) {
	@each $modifier, $value in map-get-strict($map, $key) {
		@if $wrapper {
			&--#{$modifier} {
				#{$wrapper} {
					#{$selector}: $value;
				}
			}
		}

		@else {
			&--#{$modifier} {
				#{$selector}: $value;
			}
		}
	}
}
