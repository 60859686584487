$heading: (
	colors: global-settings(colors),
	sizes: (
		mobile: (
			textlarge: (
				font-size:18px,
				line-height: 28px
			),
			normal: (
				font-size: 22px,
				line-height: 1,
			),
			default: (
				font-size: 20px,
				line-height: 1,
			),
			big: (
				font-size: 32px,
				line-height: 1,
			),
		),
		tablet: (
			textlarge: (
				font-size:18px,
				line-height: 28px
			),
			normal: (
				font-size: 24px,
				line-height: 1,
			),
			default: (
				font-size: 30px,
				line-height: 1,
			),
			big: (
				font-size: 50px,
				line-height: 1,
			),
		),
	)
);

h1.heading{
	text-transform: inherit;
	color: map-get-deep($heading, colors, white);
	padding-top: 30px;
	display: inline-block;
	&::before{
		display: block;
		height: 10px;
		content: '';
		position: absolute;
		top: 0px;
		width: 50%;
		background: map-get-deep($heading, colors, primary);
	}
}

.heading {
	@extend %heading-reset;
	font-weight: bold;
	color: map-get-deep($heading, colors, grey);
    position: relative;
	
	&__is-bold{
		font-weight: 800;
	}
	
	text-transform: uppercase;
	&__color {
		@include modifiers-deep($heading, colors, color);
	}

	&__size {
		@include responsive-modifiers($heading, sizes);
	}
}
