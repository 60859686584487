@import '../../../assets/scss/variables.scss';
@import 'media-blender';

.profilePersonalDataPage {

    h5{
        margin-bottom: 0;
    }
    .gridMain{
        display: grid;
        grid-template-columns: 1fr;

        @include media(tablet up) {
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;
            grid-gap: $pad*20;
            align-items: start;    
        }
    }
    .gridTable{
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-template-rows: auto;
        grid-gap: $pad*3 $pad*6;
        margin-top: 50px;

        @include media(tablet up) {
            grid-gap: $pad*2 $pad*10;
            margin-top: 0;
        }

        .anticon-edit{
            color: $primary-color;
        }
    }
    .item{
        position: relative;

        button{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}