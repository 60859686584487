@import "./tv.scss";

.statistic-table {
	width: 100%;
	padding: 12px;
	background-color: $color-bg-secondary;
	color: $color-fg-secondary;

	&__row {
		display: flex;
		font-size: 28px;
		border-bottom: 1px solid $color-border;
		padding: 6px 0;

		&--header {
			font-size: 28px;
			img {
				width: 42px;
			}
		}
	}

	&__cell {
		display: flex;
		flex-direction: column;
		justify-content: center;

		flex: 1;

		padding: 0 8px;

		&--50 {
			text-align: center;
			flex: 0 0 50px;
		}
		
		&--80 {
			text-align: center;
			flex: 0 0 80px;
		}

		&--one-line {
			flex-direction: row;
			justify-content: flex-start;
		}
	}

	&__title {
		margin-bottom: 32px 0;
		font-size: 40px;

		display: flex;
		align-items: center;
		justify-content: center;

		img {
			margin-right: 16px;
		}
	}

	&.left {
		margin-right: 16px;
	}
	&.right {
		margin-left: 16px;
	}
}

.title-subtitle {
	text-align: center;
	.s10 {
		font-size: 20px;
	}
	.s20 {
		font-size: 20px;
	}
	.s24 {
		font-size: 24px;
	}
	.s32 {
		font-size: 32px;
	}
	.s40 {
		font-size: 40px;
	}
	.s60 {
		font-size: 60px;
	}
	.s80 {
		font-size: 80px;
	}
	.s100 {
		font-size: 100px;
		line-height: 1;
	}

	.b {
		color: $color-fg-quaternary;
	}

	.g {
		color: $color-fg-tertiary;
	}

	.o {
		color: $color-fg-primary;
	}

	&.blur {
		opacity: 0.5;
	}
}

.green {
	color: $color-win;
}

.red {
	color: $color-lose;
}

.orange {
	color: $color-neutral;
}

.blue {
	color: #22A2FF;
}
