.secondary-header {
    background-color: $secondary-color;
    height: 80px;
    padding: 0 50px;
    color: $color-text-gray;
    line-height: 80px;
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    width: 100%;
    font-weight: 600;

    @include media(tablet up) {
      position: fixed;
      margin-left: 300px;
    }

  &__logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(desktop up) {
      justify-content: flex-start;
    }
  }


}