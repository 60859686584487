$hero:(
    height: auto,
    site-padding: global-settings(gutters, default),
    site-padding-big: global-settings(gutters, big),
    site-container: global-settings(containers, default),
    border-color: global-settings(colors, dark),
    colors: (
      background: global-settings(colors, black),
      link-normal: global-settings(colors,grey),
      link-hover: global-settings(colors,primary)
    ),
);

.hero{
  $this: &;
  padding: 0px map-get-strict($hero, site-padding);
  top: 0;
  background: map-get-deep($hero, colors, background);
  height: map-get-strict($hero, height);
  width: 100%;
  position: relative;
  margin-top: 100px;
  &__wrapper {
      max-width: map-get-strict($hero, site-container);
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding-top: map-get-strict($hero, site-padding);
      padding-bottom: map-get-strict($hero, site-padding);
      @include media(desktop up) {
        padding-top: map-get-strict($hero, site-padding-big);
        padding-bottom: map-get-strict($hero, site-padding-big);
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: auto;
        display: grid;
        grid-template-rows: auto;
        gap: 0px 50px;
        align-items: center;
      }
  } 
  &__column{
    align-items: center;
  }
}
    