$color-fg-primary: #DAA14C;
$color-fg-secondary: #DBDBDB;
$color-fg-tertiary: #ACACAC;
$color-fg-quaternary: #000000;

$color-bg-primary: #000000;
$color-bg-secondary: #262626;
$color-bg-tertiary: #1B1B1B;
$color-bg-quaternary: #DAA14C;

$color-win: #6e9d10;
$color-lose: #DB3F3F;
$color-neutral: #DAA14C;
$color-border: #434343;

$pad-tv : 32px;
