$header: (
	height: 80px,
	site-padding: global-settings(gutters, default),
  site-container: global-settings(containers, default),
	colors: (
    background: transparent,
    background-sticky: global-settings(colors,black)
	),
	z-index: 100,
);

.header {
  $this: &;
  margin-top: 80px;
  padding: 15px map-get-strict($header, site-padding);
  top: (- map-get-strict($header, height));
  background: map-get-deep($header, colors, background);
  height: map-get-strict($header, height);
  width: 100%;
  position: absolute;
  z-index: map-get-strict($header, z-index);
  // transition: transform 0.6s;
  // transform: translateY(0px);

  // Sticky
  // position: fixed;
  // top: - map-get-strict($header, height);
  // width: 100%;
  // margin-top: map-get-strict($header, height);
 
  @include media(tablet up){
		margin-top: map-get-strict($header, height);
		height: map-get-strict($header, height);
	}

  &--fixed {
    position: fixed;
  }

  &--sticky{
    position: fixed;
    z-index: 200;
    transition: transform 0.6s;
    transform: translateY(- map-get-strict($header, height));
    background-color: map-get-deep($header, colors, background-sticky);
  }

  &--show {
    transform: translateY(0);
  }

  &__navigation{
		position: relative;
		padding-bottom: 80px;
		background-color: map-get-deep($header, colors, background);
		@include media(tablet up){
			padding-bottom: map-get-strict($header, height);
		}
	}

  &__wrapper {
    max-width: map-get-strict($header, site-container);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav{
		display: none;
		@include media(desktop up) {
			display: flex;
			align-items: center;
			width: 100%;
		}
	}

  &__menu{
		width: 100%;
	}

	&__nav--left{
		display: none;
		@include media(desktop up) {
			display: flex;
			align-items: center;
			margin-right: 100px;
		}
	}

  &__nav--right{
		display: none;
		@include media(desktop up) {
			display: flex;
			align-items: center;
			margin-left: 100px;
		}
	}

  // &__column {
  //   display: flex;
  //   flex: 1 0 1px;
  //   position: relative;

  //   &--left {
  //     justify-content: right;
  //   }

  //   &--center {
  //     text-align: center;
  //     justify-content: center;
  //     flex: 0;
  //   }

  //   &--right {
  //     text-align: right;
  //     justify-content: flex-end;
  //     flex: 1;
  //     align-self: center;
  //     align-items: center;
  //   }

  //   @include media(desktop up) {
  //     &--left {
  //       order: 1;
  //       justify-content: flex-start;
  //     }

  //     &--center {
  //       order: 2;
  //       text-align: center;
  //       justify-content: center;
  //     }

  //     &--right {
  //       order: 3;
  //     }
  //   }
  // }

  &__mobile-nav{
		display: flex;
		align-items: center;
		@include media(tablet up){
			display: none;
		}
	}

	&__mobile-search{

		margin-right: 25px;
		color: global-settings(colors,white)!important;
		@include media(tablet up){
			display: none!important;
		}
	}

	&__logo {
		align-items: center;
		justify-content: center;
		align-self: center;
		display: inline-flex;
		width: auto;
		width: 100%;
		margin-right: 0px;
		text-align: center;
		.logo{
			height: 64px;
		}
		.logo__img{
			max-height: 100%;
			@include media(tablet up){
				height: auto;
			}
		}
		@include media(tablet up) {
			height: 100%;
			margin-left: 0px;
			margin-right: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-self: center;
			text-align: center;
		}
		@include media(desktop up) {
			width: auto;
		}
	}


}