.adminGameCard {
    border: 2px solid $border-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .time {
        @include text-size(larger);
        font-weight: 700;
        line-height: 1;
    }

    .label {
        color: $color-tertiary;
        font-size: 12px;
    }
    
    .status {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $pad * 1.5;
        width: 100%;
        font-weight: 600;

        img {
            height: 16px;
        }
    }
    
    &.active {
        .status {
            display: flex;
            justify-content: flex-end;
            align-content: center;
        }
    }

    &.occupied {
        .status {
            background-color: $color-white;
            color: $color-black;
            display: flex;
            justify-content: flex-end;
            align-content: center;
        }
    } 

    &.finished {
        border: 2px solid $color-disabled;
        color: $color-disabled;

        .status {
            display: flex;
            justify-content: space-between;
            background-color: $color-tertiary;
        }
    }

    &.inactive {
        border: 2px solid $color-disabled;
        color: $color-disabled;
        .status {
            background-color: #932E2E;
            color: $primary-dark-color;
        }
    }

    &.reserved,
    &.filled {
        border: 2px solid $color-disabled;
        color: $color-disabled;
        .status {
            background-color: $color-quaternary;
            display: flex;
            justify-content: space-between;
        }
    }
}