/*------------------------------------*\
    #COLORS
\*------------------------------------*/
@import './theme.scss';

$color-primary: $primary-color; //#daa14c
$color-secondary: $secondary-color; //#262626
$color-tertiary: $tertiary-color; // #434343;

$color-quaternary: #E5E5E5; //#424B5A;

$color-primary-dark: $primary-dark-color;

$color-white: #ffffff;
$color-gray-light: $gray-color-light; //#acacac
$color-gray: $color-secondary;
$color-gray-dark: $color-tertiary;
$color-black: #000000;

$color-success: #6FCF97;
$color-error: #e94c4d;
$color-warning: #F2994A;

$color-text: $color-white;
$color-text-gray: $color-gray-light;

$color-footer: #F8F8F8;

$color-message-block: #687B8B;

$checkbox-hover-color: #F4F4F4;

$border-color: #C2D1D9;

$color-disabled: #6A6868;






/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* rajdhani-regular - latin-ext */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rajdhani-v10-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/rajdhani-v10-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rajdhani-v10-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rajdhani-v10-latin-ext-regular.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-500 - latin-ext */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rajdhani-v10-latin-ext-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/rajdhani-v10-latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rajdhani-v10-latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rajdhani-v10-latin-ext-500.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-600 - latin-ext */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/rajdhani-v10-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/rajdhani-v10-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rajdhani-v10-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rajdhani-v10-latin-ext-600.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-700 - latin-ext */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rajdhani-v10-latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/rajdhani-v10-latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rajdhani-v10-latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rajdhani-v10-latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rajdhani-v10-latin-ext-700.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

$font-family: 'Rajdhani', sans-serif;
$font-weight: 700;

$font : $font-family;

/**
* Font-weight
*/
$light: 300;
$normal: 400;
$medium: 600;
$bold: 800;
$strong: 900;

/**
* Spacing Defaults
*/
$pad : 5px;


/*------------------------------------*\
    #BORDERS
\*------------------------------------*/

/**
* Border Width
*/
$border-s: 1px;
$border-m: 1.5px;
$border-l: 2px;

$border: $border-s solid $border-color;

$border-radius: 4px;

$box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

//Typography
$typography-map-custom: (
  mobile: (
    giant:          40px,
    huge:           32px,
    biggest:        28px,
    bigger:         24px,
    big:            22px,
    largest:        20px,
    larger:         18px,
    large:          16px,
    default:        14px,
    medium:         12px,
    small:          11px,
    smaller:        10px,
    tiny:           9px
  ),
  desktop: (
    giant:          48px,
    huge:           40px,
    biggest:        32px,
    bigger:         28px,
    big:            24px,
    largest:        22px,
    larger:         20px,
    large:          18px,
    default:        16px,
    medium:         14px,
    small:          12px,
    smaller:        11px,
    tiny:           10px
  )
);


$base-content-full-width: 1330px;
$base-content-width: $base-content-full-width + ($pad*10);

$base-content-full-width-s: 900px;
$base-content-width-s: $base-content-full-width-s + ($pad*10);

$base-content-full-width-xs: 700px;
$base-content-width-xs: $base-content-full-width-xs + ($pad*10);

$panel-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
