$menu-copyright: (
	link-padding: 0 15px,
	link-modifiers: (
		normal: (
			color: global-settings(colors, grey),
		),
		hover: (
			color: global-settings(colors, primary),
		)
	),
);

.copyright {
    display: flex;
    justify-content: space-between;
}

.menu-copyright {
	@extend %list-reset;
	$this: &;
	height: 100%;
	display: flex;
	align-items: center;

	&__item{
		white-space: nowrap;
	}

	&__link {
		text-decoration: none;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: map-get-strict($menu-copyright, link-padding);
		transition: 0.3s color ease-in-out;

		@include link($menu-copyright, link-modifiers);
	}
}