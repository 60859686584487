$menu-sidebar: (
    link-padding: (
        mobile: (
            padding: 15px 0px,
        ),
        tablet: (
            padding: 15px 0px,
        ),
        large:(
            padding: 15px 0px,
        )
    ),
	link-modifiers: (
		normal: (
			color: global-settings(colors, black),
		),
		hover: (
			color: global-settings(colors, primary),
		),
	),
    colors:(
        border-color: #434343,
    )
);

.menu-sidebar {
    $this: &;
	@extend %list-reset;

	&__items{
        @extend %list-reset;
        height: 100%;
        display: flex;
		flex-direction: column;
     
    }

    &__item{
        display: flex;
        flex: 1 0 auto;
        border-bottom: 1px solid map-get-deep($menu-sidebar,colors,border-color);
    }   

	&__link {
		text-decoration: none;
		height: 100%;
		display: flex;

		@include responsive($menu-sidebar,link-padding);
		transition: 0.3s color ease-in-out;
        flex: 1 0 auto;
		@include link($menu-sidebar, link-modifiers);
	}
    &__link-text{
        font-weight: 700;
        color: $color-text-gray;
        position: relative;
        text-transform: uppercase;
        transition: all 0.3s ease-in 0s;
        &:hover{
            color: $color-primary;
            transform: translateX(5px);
        }
    }
    &__item--current_page_item{
        color: $color-primary;
        #{$this}__link-text{
            font-weight: 700;
            color: $color-primary;
            transform: translateX(0px);
            transition: all 0.3s ease-in 0s;
            background-size: 100% 100%;
            //Experiment
            &:hover{
                color: $color-primary;
                transform: translateX(5px);
            }

        }
    }



}
