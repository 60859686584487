@import '../../../assets/scss/tv.scss';

.info-page {
	width: 100%;
	height: 100%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    font-size: 60px;

    img {
        height: 500px;
    }

    .timer {
        position: absolute;
        bottom: 64px;
        color: black;
        background-color: $primary-color;
        padding: 12px 64px;
        border-radius: 8px;

        &__label {
            font-size: 32px;
            padding: 0;
        }
        &__value {
            font-size: 80px;
            padding: 0;
            line-height: 1em;
        }
    }
}
