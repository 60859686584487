.login {
    &__box {
        background-color: $color-black;
        width: 100vw;
        padding: $pad * 5;

        @include media(tablet up) {
            width: 500px;
            padding: $pad * 10;
        }
    }
}