@import "../../../assets/scss/tv.scss";

.match-game-page {
	width: 100%;
	height: 100%;
	display: flex;

	color: $color-fg-secondary;

	.live-feed {
		padding: 42px 32px;

		flex: 1;
		background-color: $color-bg-secondary;

		&__title {
			padding: 16px 0;
			font-size: 40px;
			text-align: center;
			color: $color-fg-primary;
			border-bottom: 6px solid $color-fg-primary;
		}

		&__box {
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr 1fr;
			grid-gap: 8px;

			font-size: 28px;

			padding: 24px 8px;
			border-bottom: 2px solid $color-border;

			div {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
			}

			&--custom {
				grid-column: 1/3;
				grid-row: 1/3;
			}
		}
	}

	.game-status {
		flex: 3;
	}

	.game-status-top {
		padding: 24px 32px;

		display: flex;
		justify-content: space-between;
		align-items: center;

		&__left {
			padding-left: 48px;
			min-width: 250px;
		}

		&__right {
			display: flex;
			justify-content: space-evenly;
			align-items: flex-start;
			text-align: center;

			background-color: $color-bg-secondary;
			.box {
				padding: 32px;
			}
			.additional {
				font-size: 20px;
				display: block;
				margin-top: -12px;

				img {
					height: 24px;
					width: 24px;
				}
			}
		}
	}

	.game-status-bottom {
		.spliter {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			
			&--end {
				align-items: flex-end;
			}

			.title-subtitle {
				line-height: 1;
			}
		}

		.title-box {
			flex: 1;
			text-align: center;
			font-size: 50px;
			color: $color-fg-primary;

			div {
				font-size: 42px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img {
				width: 32px;
				margin-right: 16px;
			}
		}

		.statistic-table {
			margin: 32px;

			&.left {
				margin-right: 16px;
			}
			&.right {
				margin-left: 16px;
			}

			&__row {
				&--header {
					font-size: 24px;
					img {
						width: 24px;
					}
				}
			}
			&__cell {
				font-size: 28px;
			}
		}
	}
}
