.profile-statistics-page {
    .title {
        color: #acacac;
        font-size: 26px;
        font-weight: 700;
    }

    .subtitle {
        color: #acacac;
        font-size: 24px;
        margin-top: 16px;
    }

    .box {
        background: #262626;
        padding: 16px 8px;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &__wide {
            flex-direction: column;
            width: 100%;

            .row__item {
                width: 100%;
            }
        }

        &__item {
            flex: 1;
        }
    }

    .mini-box {
        text-align: center;
        &__title {
            font-size: 18px;
            color: #acacac;
            font-weight: 700;
        }

        &__subtitle {
            font-size: 36px;
            color: #daa14c;
            font-weight: 700;
        }

        &__subtitle2 {
            font-size: 27px;
            color: #daa14c;
            font-weight: 700;
        }

        &__footer {
            font-size: 18px;
            color: #acacac;
            font-weight: 700;
        }

        &--border-left {
            border-left: 1px solid #434343;
        }
    }
}
