$footer-menu: (
	link-padding: 0 15px,
	link-modifiers: (
		normal: (
			color: global-settings(colors, grey),
		),
		hover: (
			color: global-settings(colors, primary),
		)
	),
);

.footer-menu {
	@extend %list-reset;
	$this: &;
	height: 100%;
	display: flex;
	
	flex-direction: column;
	align-items: flex-start;
	margin: 0 -15px;
	padding-top: 25px;
	@include media(desktop up){
		flex-direction: row;
		align-items: center;
		padding-top: 0;
	}

	&__item{

	}

	&__link {
		text-decoration: none;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: map-get-strict($footer-menu, link-padding);
		transition: 0.3s color ease-in-out;

		@include link($footer-menu, link-modifiers);
	}

	&__link-text{
		text-transform: uppercase;
    	font-weight: 700;
	}
}
