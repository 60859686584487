$header-drawer-menu: (
	// link-padding: (
    //     mobile: (
    //         padding: 0 0 25px 0,
    //     )
    // ),
	// link-modifiers: (
	// 	normal-color: global-settings(colors, white),
	// 	hover-color: global-settings(colors, primary),
	// ),
    link-padding: 15px 0px,
	link-modifiers: (
		normal: (
			color: global-settings(colors, white),
		),
		hover: (
			color: global-settings(colors, primary),
			font-weight: bold,
		)
	),
);

.header-drawer-menu {
    $this: &;
    padding: 25px;
	box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.1);
    // font-size: 18px;
    // height: 100%;
    // padding-top: 50px;

    @include media(desktop up) {
        display: none;
    }
    &__items{
        @extend %list-reset;
        font-size: 18px;
        // height: 100%;
        // display: flex;
        // align-items: flex-start;
        // flex-direction: column;
    }

    &__item{
        // display: flex;
    }

	&__link {
        padding: 15px 0px;
		display: block;
		text-decoration: none;
        text-transform: uppercase;
		// @include responsive($header-drawer-menu,link-padding);
        padding: map-get-strict($header-drawer-menu, link-padding);
		transition: 0.3s color ease-in-out;
		// @include link($header-drawer-menu, link-modifiers);
        @include link($header-drawer-menu, link-modifiers);
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
	}
    // &__link-text{
    //     font-weight: 700;
    //     // color: map-get-deep($header-drawer-menu,link-modifiers,normal-color);
    //     position: relative;
    //     text-transform: uppercase;
        
    //     @include underline();
    //     transition: all 0.3s ease-in 0s;
    //     &::before{
    //         content: "";
    //         position: absolute;
    //         top: 2px;
    //         left:-8px;
    //         width: 5px;
    //         height: 5px;
    //         background-color: transparent;
    //         display: none;
    //         transition: background-color .3s ease,opacity .3s ease;
    //         border-radius: $border-radius;
    //     }

    //     &:hover{
    //         // color: map-get-deep($header-drawer-menu,link-modifiers,hover-color);
    //         transform: translateY(-5px);
    //         &::before{
    //         background-color: $color-primary;
    //         }
    //     }
    // }
    &__item--current_page_item{
        color: $color-primary;
        #{$this}__link-text{
            font-weight: 700;
            color: $color-primary;
            transform: translateY(0px);
            @include underline-text();
            transition: all 0.3s ease-in 0s;
            background-size: 100% 100%;
            //Experiment
            &:hover{
                color: $color-primary;
                transform: translateY(-1px);
            }

            &::before{
                // background-color: map-get-deep($header-drawer-menu,link-modifiers,hover-color);
            // display: block;
            }
        }
    }
}
