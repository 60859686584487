@mixin underline-link($thickness: 2px, $duration: 0.3s, $timing-function: cubic-bezier(0.79, 0.01, 0.22, 0.99), $color: currentColor, $breakpoint: 1080px) {
    position: relative;
  
    &:hover {
      text-decoration: none;
    }

    &::before{
        content: '';

        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: $thickness;

        transform: scaleX(1);
        transform-origin: left center;
        transition: transform $duration $timing-function 0s, transform-origin 0s step-end $duration;

        background-color: rgba(0,0,0,.5);
        z-index: 0;
    }
  
    &::after {
      content: '';
  
      position: absolute;
      right: 0;
      bottom: 0;
  
      width: 100%;
      height: $thickness;
  
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform $duration $timing-function 0s, transform-origin 0s step-end $duration;
  
      background-color: $color;
      z-index: 1;
    }
  
    @media (min-width: $breakpoint) {
      &:hover::after {
        content: '';
        transform: scaleX(1);
        transform-origin: right center;
      }
    }
  }

  @mixin underline-text($thickness: 10%, $duration: 0.5s, $timing-function: cubic-bezier(0.79, 0.01, 0.22, 0.99), $color: currentColor, $wrapper: false, $element: '.underline-text', $state: 'hover') {
	$thickness: 100% - $thickness;

	$underline-inactive-state: (
		display: inline,

		transition: (background-size $duration $timing-function 0s, background-position 0s step-end $duration),
		text-decoration: none,

		background-image: linear-gradient(transparent $thickness, $color $thickness, $color 100%),
		background-repeat: no-repeat,
		background-position-y: bottom,
		background-size: 0% 100%,
	);

	$underline-active-state: (
		background-position-x: right,
		background-position-y: bottom,
		background-size: 100% 100%,
	);

	@if $wrapper {
		text-decoration: none;

		#{$element} {
			@include for-each-attribute($underline-inactive-state);
		}

		&:#{$state} {
			#{$element} {
				@include for-each-attribute($underline-active-state);
			}
		}
	}

	@else {
		@include for-each-attribute($underline-inactive-state);

		&:#{$state} {
			@include for-each-attribute($underline-active-state);
		}
	}
}

@mixin underline($thickness: 2px, $duration: 0.3s, $timing-function: cubic-bezier(0.79, 0.01, 0.22, 0.99), $color: currentColor, $breakpoint: 1080px) {
	position: relative;

	&:hover {
		text-decoration: none;
	}

	&::after {
		content: '';

		position: absolute;
		right: 0;
		bottom: 0;

		width: 100%;
		height: $thickness;

		transform: scaleX(0);
		transform-origin: left center;
		transition: transform $duration $timing-function 0s, transform-origin 0s step-end $duration;

		background-color: $color;
	}

	@media (min-width: $breakpoint) {
		&:hover::after {
			content: '';
			transform: scaleX(1);
			transform-origin: right center;
		}
	}
}