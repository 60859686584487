.systemPage {

    h1 {
        color: white;
        font-size: 24px;
        font-weight: 700;
    }

    h2 {
        color: white;
        font-size: 20px;
        font-weight: 700;
        margin-top: 32px;
    }

    .banner {
        text-align: center;
        padding: 16px 0;
        border: 1px solid;
        font-size: 24px;
    }

    .gameStatus {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 16px;
    }

    .actions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 16px;
        flex-direction: column;

        @include media(desktop up) {
            flex-direction: row;
        }
    }
}
