.color {
  height: 14px;
  border-radius: 2px;
  background: #fff;
}
.swatch {
  width: 100%;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
}
.popover {
  position: absolute;
  z-index: 2;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
