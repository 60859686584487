@import '../../../assets/scss/tv.scss';

.tv-page {
    width: inherit;
    height: inherit;

    font-weight: 700;
    
    overflow: hidden;
    
    background-color: $color-bg-primary;
    color: $color-fg-primary;

	.debug {
		position: fixed;
		right: 0;
		bottom: 0;
        color: $color-border;
        font-size: 12px;
	}
}
