@import 'media-blender';

.reservationSummary {
    display: grid;
    row-gap: 50px;
    .item {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 25px;
        
        div:nth-child(3) {
            grid-row: 2;
            grid-column: 1 / 5;
            @include media(desktop up) {
                grid-row: auto;
                grid-column: auto;
            }
        }

        @include media(desktop up) {
            grid-template-columns: 1fr 1fr 2fr 1fr auto;
            column-gap: 25px;
        }

        .date {
            font-weight: 700;
        }

        .time {
            font-weight: 700;
        }

        .tank {
            font-weight: 700;
        }

        .price {
            font-weight: 700;
        }

        .delete {
            font-weight: 700;
            font-size: 24px;
        }
    }
}

.actions {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 25px;

    @include media(desktop up) {
        grid-template-columns: 1fr auto;
        justify-items: end;
        column-gap: 25px;
    }

    button {
        width: 200px;
        @include media(desktop up) {
            width: 350px;
        }
    }
}