.heading {
    color: $color-primary;
    margin-bottom: $pad*2;

    &__h1 {
        @include text-size(huge);
        font-weight: bold;
        color: $color-primary;
        margin-bottom: $pad*2;
    }

    &__h2 {
        @include text-size(larger);
        font-weight: bold;
        color: $color-text;
        margin-bottom: $pad*2;
    }

    &__h3 {
        @include text-size(large);
        color: $color-text;
        margin-bottom: $pad*2;
    }

    &__h4 {
        @include text-size(medium);
        color: $color-text;
        margin-bottom: $pad*2;
    }

    &__h5 {
        @include text-size(smaller);
        color: $color-text;
        margin-bottom: $pad*2;
    }

    &--gray {
        color: $color-text-gray;
    }

    &--white {
        color: $color-text;
    }

    &--primary {
        color: $color-primary;
    }

    &--no-margin {
        margin-bottom: 0!important;
    }

    &--margin-25 {
        margin-bottom: $pad * 5;
    }

    &--margin-50 {
        margin-bottom: $pad * 10;
    }
}

h1 {
    @include text-size(huge);
    font-weight: bold;
    color: $color-primary;
    margin-bottom: $pad*2;
}

h2 {
    @include text-size(larger);
    font-weight: bold;
    color: $color-text;
    margin-bottom: $pad*2;
}

h3 {
    @include text-size(large);
    color: $color-text;
    margin-bottom: $pad*2;
}

h4 {
    @include text-size(medium);
    color: $color-text;
    margin-bottom: $pad*2;
}

h5 {
    @include text-size(smaller);
    color: $color-text;
    margin-bottom: $pad*2;
}

.bold {
    font-weight: 700;
}

.text-center {
    text-align: center;
}