.content {
    flex: auto;
    margin-top: 105px;

    &.has-sider {
        @include media(tablet up) {
            margin-left: 325px;
            margin-right: 25px;
        }
    }
}