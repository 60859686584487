*,
*::after,
*::before {
	@include box-sizing;
}

body {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    @include text-size(default);
    @include font-smoothing;
    font-family: $font;	
    font-weight: 500;
    // line-height: calc(12px + 1.05vw);
}


//------------------------------------------------

html, body {
    width: 100%;
    height: 100%;
}

#root {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

//------------------------------------------------

.no-padding {
    padding: 0;
}
.no-margin {
    margin: 0;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .item {
        margin-right: 16px;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.center {
        justify-content: center;
    }
}

.center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.w100-h100 {
    width: 100%;
    height: 100%;
}

.mb4 { margin-bottom: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }

.mb8 { margin-bottom: 8px; }
.mt8 { margin-top: 8px; }
.ml8 { margin-left: 8px; }
.mr8 { margin-right: 8px; }

.mb16 { margin-bottom: 16px; }
.mt16 { margin-top: 16px; }
.ml16 { margin-left: 16px; }
.mr16 { margin-right: 16px; }

.mb24 { margin-bottom: 24px; }
.mt24 { margin-top: 24px; }
.ml24 { margin-left: 24px; }
.mr24 { margin-right: 24px; }

.mb32 { margin-bottom: 32px; }
.mt32 { margin-top: 32px; }
.ml32 { margin-left: 32px; }
.mr32 { margin-right: 32px; }

.mb48 { margin-bottom: 48px; }
.mt48 { margin-top: 48px; }
.ml48 { margin-left: 48px; }
.mr48 { margin-right: 48px; }

.mb64 { margin-bottom: 64px; }
.mt64 { margin-top: 64px; }
.ml64 { margin-left: 64px; }
.mr64 { margin-right: 64px; }

.mb25 {
    margin-bottom: 25px;
}

.mt25 {
    margin-top: 25px;
}

.mb50 {
    margin-bottom: 50px;
}

.mt50 {
    margin-top: 50px;
}

.site-container {
    @extend %site-container;
}

.drawer-container {
    position: relative;
    //overflow: hidden;
}

.position-absolute {
    position: absolute !important;
}