@import "../../../assets/scss/tv.scss";

.summmary-page {
	width: 100%;
	height: 100%;
	display: flex;

	.section-left {
		flex: 5;
		background-color: $color-bg-secondary;

		.box {
			width: calc(100% - 150px);
			margin: 32px 0 16px 0;
			padding-top: 16px;
			background-color: $color-bg-quaternary;
			color: $color-fg-quaternary;

			display: flex;
			align-items: flex-end;

			.title-subtitle {
				padding: 16px 64px;
			}

			.name {
				margin-bottom: 32px;
			}

			.team {
				font-size: 42px;
				line-height: 1;

				&.draw {
					margin-left: 42px;
					img {
						height: 48px;
						margin-bottom: 16px;
					}
				}
			}

			.status {
				font-size: 30px;
				line-height: 1;
				margin-left: 42px;
			}
		}

		.stats {
			h1 {
				font-size: 32px;
				color: $color-fg-secondary;
				font-weight: 700;
				text-align: center;
			}
		}
	}

	.section-right {
		display: flex;
		flex-direction: column;

		flex: 7;
		padding: 32px 32px;

		.section-up {
			flex: 1;
			background-color: $color-bg-secondary;

			.banner {
				display: flex;
				justify-content: space-around;
				align-items: flex-start;

				padding: 32px 0;
			}

			.spliter {
				display: flex;
				justify-content: space-between;

				margin: 0 16px;

				.statistic-table {
					background-color: $color-bg-tertiary;
				}
			}
		}

		.section-down {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			margin-top: 32px;
			padding: 32px 64px 16px 32px;

			background-color: $color-bg-secondary;

			.title {
				font-size: 40px;
				color: $color-fg-tertiary;
			}
			.time {
				font-size: 85px;
				color: $color-fg-primary;
			}
		}
	}

	.statistic-table {
		flex: 1;

		&__row {
			font-size: 28px;
		}

		&__title {
			font-size: 32px;
			color: $color-fg-primary;
		}

		img {
			height: 20px;
			margin-right: 8px;
		}

		.players {
			font-size: 18px;
		}
	}

	.highlight {
		background-color: #daa14c4d;
	}
}
