@import "../../../assets/scss/tv.scss";

.between-rounds-page {
	width: 100%;
	height: 100%;
	padding: 32px;

	.banner {
		width: 100%;
		padding-top: 24px;

		display: flex;
		justify-content: space-around;
		align-items: flex-start;

		background-color: $color-bg-secondary;

		.additional {
			font-size: 24px;
			display: block;
			margin-top: -12px;

			img {
				height: 24px;
				width: 24px;
			}
		}
	}

	.round-result {
		position: relative;
		margin: 24px 0;

		.win {
			position: absolute;
			bottom: 12px;
			padding: 8px 16px;
			font-size: 32px;
			background-color: $color-bg-quaternary;
			color: $color-fg-quaternary;

			&.left {
				left: 0;
			}

			&.right {
				right: 0;
			}
		}
	}

	.spliter {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.statistic-table__row {
		padding: 8px 0;
	}

	.title-box {
		flex: 1;
		text-align: center;
		font-size: 42px;

		img {
			width: 32px;
			margin-right: 16px;
		}
	}

	.orange {
		color: $color-neutral;
	}

	.title-subtitle .s80 {
		line-height: 1;
	}
}
