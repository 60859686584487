.sider {
    &.ant-layout-sider {
        left: 0;

        @include media(tablet up) {            
            height: 100vh;
            position: fixed;
            overflow: auto;
            background-color: $color-black;
        }
    }

    .adminLogo {
        &__logo {
            padding: 10px;
            height: 80px;
        }

        &__imglogo {
            height: 60px;
        }
    }
}