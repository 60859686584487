$user-rank: (
	background-color: global-settings(colors, black),
	label-sizes:(
		mobile: (
			font-size: 14px,
			font-weight: bold,
			line-height: 1
		),
		tablet:(
			font-size: 14px,
		),
	),
	value-sizes:(
		mobile: (
			font-size: 14px,
		),
		tablet:(
			font-size: 16px,
		),
	),
);

.user-rank {
	@extend %list-reset;
	$this: &;
	display: flex;
	align-items: center;
	overflow: hidden;
    background-color: map-get-strict($user-rank, background-color);
	padding: 15px;

	&__content{
		//display: flex;
	}

	&__label {
		display: block;
		text-transform: uppercase;
		@include responsive($user-rank,label-sizes);

	}

	&__value {
		padding: 0;
		display: block;
		@include responsive($user-rank,value-sizes);
	}

	&__svg {
		display: block;
		margin-right: 15px;
	}
}
