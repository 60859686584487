@import 'media-blender';

.reservationConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    .item {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        column-gap: 25px;
        margin-bottom: 20px;

        .date {
            font-weight: 700;
        }

        .time {
            font-weight: 700;
        }

        .tank {
            font-weight: 700;
        }

        .price {
            font-weight: 700;
        }

        .delete {
            font-weight: 700;
            font-size: 24px;
        }
    }
}

.confirmation-actions {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;

    button {
        width: 300px;
        @include media(desktop up) {
            width: 350px;
        }
    }
}